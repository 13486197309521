import styled from 'styled-components'
import { Checkbox, Button } from 'components/common'

export const CheckboxComponent = styled(Checkbox)`
  margin-bottom: 10px;
`

export const Buttons = styled.div`
  
`

export const WidgetButton = styled(Button)`
  padding: 4px 50px;
`