export enum Constants {
  CAMPAIGN_SET_TOKEN_ADDRESS = 'CAMPAIGN_SET_TOKEN_ADDRESS',
  CAMPAIGN_SET_TITLE = 'CAMPAIGN_SET_TITLE',
  CAMPAIGN_SET_DESCRIPTION = 'CAMPAIGN_SET_DESCRIPTION',
  CAMPAIGN_SET_LOGO_URL = 'CAMPAIGN_SET_LOGO_URL',
  CAMPAIGN_SET_DROP_ADDRESS = 'CAMPAIGN_SET_DROP_ADDRESS',
  CAMPAIGN_SET_MERKLE_TREE = 'CAMPAIGN_SET_MERKLE_TREE',
  CAMPAIGN_SET_LOADING = 'CAMPAIGN_SET_LOADING',
  CAMPAIGN_SET_IPFS = 'CAMPAIGN_SET_IPFS',
  CAMPAIGN_SET_TYPE = 'CAMPAIGN_SET_TYPE',
  CAMPAIGN_SET_DECIMALS = 'CAMPAIGN_SET_DECIMALS',
  CAMPAIGN_CLEAR = 'CAMPAIGN_CLEAR',
  CAMPAIGN_SET_ASSETS = 'CAMPAIGN_SET_ASSETS',
  CAMPAIGN_SET_SYMBOL = 'CAMPAIGN_SET_SYMBOL',
  CAMPAIGN_SET_WALLET = 'CAMPAIGN_SET_WALLET',
  CAMPAIGN_SET_PROXY_CONTRACT_ADDRESS = 'CAMPAIGN_SET_PROXY_CONTRACT_ADDRESS',
  CAMPAIGN_SET_APPROVED = 'CAMPAIGN_SET_APPROVED',
  CAMPAIGN_SET_ID = 'CAMPAIGN_SET_ID',
  CAMPAIGN_SET_SECURED = 'CAMPAIGN_SET_SECURED',
  CAMPAIGN_SET_SIGNER_KEY = 'CAMPAIGN_SET_SIGNER_KEY',
  CAMPAIGN_SET_SIGNER_ADDRESS = 'CAMPAIGN_SET_SIGNER_ADDRESS',
  CAMPAIGN_SET_SPONSORED = 'CAMPAIGN_SET_SPONSORED',
  CAMPAIGN_SET_LINKS = 'CAMPAIGN_SET_LINKS',
  CAMPAIGN_SET_TOKEN_STANDARD = 'CAMPAIGN_SET_TOKEN_STANDARD',
  CAMPAIGN_SET_CLAIM_PATTERN = 'CAMPAIGN_SET_CLAIM_PATTERN',
  CAMPAIGN_SET_LINKS_GENERATE_LOADER = 'CAMPAIGN_SET_LINKS_GENERATE_LOADER'
}