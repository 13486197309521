import { Widget, Button, Input, ProgressBar } from "components/common"
import styled, { css } from "styled-components"

export const Container = styled.div`
  position: relative;
`

export const WidgetComponent = styled(Widget)``

export const WidgetInfo = styled.div`
  display: grid;
  max-width: 500px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const WidgetSubtitle = styled.h4`
  margin: 0;
  text-align: right;
  font-size: 16px;
`

export const WidgetValue = styled.div``

export const Buttons = styled.div`
  display: flex;
`

export const WidgetButton = styled(Button)`
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`

export const InputComponent = styled(Input)`
  margin-bottom: 10px;
`

export const PopupFormContent = styled.div`
  margin-bottom: 20px;
`

export const PopupForm = styled.form`
`


type TLinksIndicator = {
  mapped?: boolean
}
export const LinksIndicator = styled.div<TLinksIndicator>`
  ${props => !props.mapped && css`
    color: ${props.theme.tagWarning};
  `}
`

export const StyledProgressBar = styled(ProgressBar)`
  min-width: 100%;
  margin-bottom: 20px;
`