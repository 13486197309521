import styled from 'styled-components'
import { Button } from 'components/common'

export const WidgetSummaryData = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`

export const WidgetButton = styled(Button)`
  padding: 4px 50px;
`