const themes = {
	light: {
		primaryTextColor: '#000', // main ccolor of text
		secondaryTextColor: '#FFF', // secondary color of text
		additionalTextColor: '#979797', // secondary color of text
		disabledTextColor: '#979797', // disabled color of text
		dangerTextColor: '#FF2C2C', // danger color of text
		noteTextColor: '#C5C7CD', // note color of text
		primaryBorderColor: '#e5e5e5',
		secondaryBorderColor: '#FFF',
		extraBorderColor: '#979797',
		disabledBorderColor: '#C5C7CD',
		disabledColor: '#DFE0EB',
		primaryColor: '#F7F8FC', // main color of bg
		secondaryColor: '#363740', // secondary coplor of bg
		blankColor: '#f1f3f4', // secondary coplor of bg
		backgroundColor: '#FFF',
		menuItemActive: '#3E4049',

		primaryHighlightColor: '#0025ff',
		secondaryHighlightColor: '#4047EC',
		invertedCommunityColor: '#4B2FF5',

		buttonActionBackgroundColor: '#0025ff',

		buttonDefaultBackgroundColor: 'transparent',
		buttonDefaultBackgroundHoverColor: 'transparent',

		buttonGradient: 'linear-gradient(to right, #00B2FF 0%, #FA00FF 50%, #00B2FF 100%)',

		tagDefaultColor: '#DFE0EB',
		tagErrorColor: '#FF2C2C',
		tagInfoColor: '#0025ff',
		tagSuccessColor: '#21F142',
		tagWarning: '#F6B40A',

		noteDefaultBackgroundColor: 'rgba(0, 37, 255, .2)',

		statusSuccessColor: '#19E46A',
		statusStoppedColor: '#DFE0EB'
	},
	dark: {
		primaryTextColor: 'red',
		secondaryTextColor: '#363740',
		primaryBorderColor: '#DFE0EB',
		secondaryBorderColor: '#FFF',
		primaryColor: '#F7F8FC',
		secondaryColor: '#363740',
		menuItemActive: '#3E4049'
	}
}

export default themes